// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      // light: {
      //   primary: '#000000',
      //   secondary: '#050B1F',
      //   accent: '#204165',
      // },
      dark: {
        primary: '#ffffff',
        secondary: '#0B1C3D',
        accent: '#204165',
      },
    },
  },
})
