import axios from 'axios'
import { api } from '../../config'
import * as types from '../mutation-types'

/**
 * Initial state
 */
export const state = {
  isLoading: false,
  portfolios: [],
  portfolio: {
    id: -1,
    title: '',
    description: '',
    main_icon: '',
    enabled: 0,
  },
}

/**
 * Actions
 */
export const actions = {
  async fetchPortfolios ({ commit }) {
    commit(types.START_FETCH_PORTFOLIOS)
    try {
      const { data } = await axios.get(api.path('portfolio'))
      const portfolios = data.data
      console.log('portfolios data: ' + JSON.stringify(portfolios))
      if (process.env.NODE_ENV === 'production') commit(types.SET_PORTFOLIOS_ITEMS, portfolios)
      else {
        setTimeout(function () {
          commit(types.SET_PORTFOLIOS_ITEMS, portfolios)
        }, 3000)
      }
    } catch (e) {
      console.log(e)
      commit(types.FETCH_PORTFOLIOS_FAILURE)
    }
  },
  async fetchPortfolio ({ commit }, { id }) {
    console.log('fetchPortfolio id:' + id)
    try {
      const { data } = await axios.get(api.path('portfolio.portfolio', { id: id }))
      if (process.env.NODE_ENV === 'development') console.log('fetchPortfolio action data: ' + JSON.stringify(data.data))
      const portfolio = data.data
      // if (process.env.NODE_ENV === 'development') console.log("fetchPortfolio action data: " + JSON.stringify(pulseDiagnostic));
      commit(types.SET_PORTFOLIO_ITEM, portfolio)
    } catch (e) {
      console.log(e)
      commit(types.FETCH_PORTFOLIO_FAILURE)
    }
  },
}

/**
 * Mutations
 */
export const mutations = {
  [types.START_FETCH_PORTFOLIOS] (state) {
    state.isLoading = true
  },
  [types.SET_PORTFOLIOS_ITEMS] (state, portfolios) {
    state.isLoading = false
    state.portfolios = portfolios
  },
  [types.FETCH_PORTFOLIOS_FAILURE] (state) {
    state.isLoading = false
    state.portfolios = []
  },
  [types.SET_PORTFOLIO_ITEM] (state, portfolio) {
    state.isLoading = false
    state.portfolio = portfolio
  },
  [types.FETCH_PORTFOLIO_FAILURE] (state) {
    state.isLoading = false
    state.portfolio = {
      id: -1,
      title: '',
      description: '',
      main_icon: '',
      enabled: 0,
    }
  },
}

/**
 * Getters
 */
export const getters = {
  isLoading: state => state.isLoading,
  portfolios: state => state.portfolios,
  portfolio: state => state.portfolio,
}
