import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)],
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

const state = {
    appLang: 'en',
}

const actions = {
    setAppLang ({ commit }, code) {
        commit('setAppLang', code)
    },
    setAnalyticsDays ({ commit }, day) {
      commit('setAnalyticsDays', day)
    },
}

const mutations = {
    setAppLang (state, code) {
        state.appLang = code
    },
    setAnalyticsDays (state, day) {
      state.analyticsDays = day
    },
}

const getters = {
    appLang: state => state.appLang,
    analyticsDays: state => state.analyticsDays,
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules,
})
