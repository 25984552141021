// export const siteUrl = 'https://back.ksystems.dev73.ksyste.ms' // Laravel.siteUrl,
export const siteUrl = process.env.VUE_APP_MY_ENV_SITE_URL // Laravel.siteUrl,
// const apiUrl = 'https://back.ksystems.dev73.ksyste.ms/api' // Laravel.apiUrl
// const apiUrl = 'http://ksystems.local/api' // Laravel.apiUrl
const apiUrl = process.env.VUE_APP_MY_ENV_API_URL // Laravel.apiUrl

export const settings = {
  siteName: 'KSystems',
}

class URL {
  constructor (base) {
    this.base = base
  }

  path (path, args) {
    path = path.split('.')
    let obj = this
      let url = this.base

    for (let i = 0; i < path.length && obj; i++) {
      if (obj.url) {
        url += '/' + obj.url
      }

      obj = obj[path[i]]
    }
    if (obj) {
      url = url + '/' + (typeof obj === 'string' ? obj : obj.url)
    }

    if (args) {
      for (const key in args) {
        url = url.replace(':' + key, args[key])
      }
    }

    return url
  }
}

export const api = Object.assign(new URL(apiUrl), {
  url: '',

  services: {
    url: 'services',
  },

  stacks: {
    url: 'stack',
  },

  portfolio: {
    url: 'portfolio',

    portfolio: ':id',
  },

  vacancy: {
    url: 'vacancy',
  },

  candidates: {
    url: 'candidates',

    add: 'add',
  },

  requests: {
    url: 'requests',

    add: 'add',
  },

  verify: {
    url: 'verify',

    type: ':type',
  },
})
