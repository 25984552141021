import axios from 'axios'
import { api } from '../../config'
import * as types from '../mutation-types'

/**
 * Initial state
 */
export const state = {
  isLoading: false,
  selectedItems: [],
  services: [],
}

/**
 * Actions
 */
export const actions = {
  async addSelectedItem ({ commit }, { item }) {
    var items = state.selectedItems
    items.push(item)
    commit(types.SET_SERVICES_SELECTED_ITEMS, items)
  },
  async removeSelectedItem ({ commit }, { item }) {
    var items = state.selectedItems.filter(function (e) { return e.id !== item.id })
    commit(types.SET_SERVICES_SELECTED_ITEMS, items)
  },
  async resetSelectedItem ({ commit }) {
    commit(types.SET_SERVICES_SELECTED_ITEMS, [])
  },
  async fetchServices ({ commit }) {
    try {
      const { data } = await axios.get(api.path('services'))
      console.log('fetchServices action data: ' + JSON.stringify(data))
      const services = data.data
      console.log('services data: ' + JSON.stringify(services))
      commit(types.SET_SERVICES_ITEMS, services)
    } catch (e) {
      console.log(e)
      commit(types.FETCH_SERVICES_FAILURE)
    }
  },
}

/**
 * Mutations
 */
export const mutations = {
  [types.SET_SERVICES_ITEMS] (state, services) {
    state.isLoading = false
    state.services = services
  },
  [types.FETCH_SERVICES_FAILURE] (state) {
    state.isLoading = false
    state.services = []
  },
  [types.SET_SERVICES_SELECTED_ITEMS] (state, items) {
    state.selectedItems = items
  },
}

/**
 * Getters
 */
export const getters = {
  isLoading: state => state.isLoading,
  selectedItems: state => state.selectedItems,
  services: state => state.services,
}
