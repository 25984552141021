/**
 * services.js
 */
export const SET_SERVICES_ITEMS = 'SET_SERVICES_ITEMS'
export const SET_SERVICES_SELECTED_ITEMS = 'SET_SERVICES_SELECTED_ITEMS'
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE'

/**
 * stack.js
 */
export const SET_STACKS_ITEMS = 'SET_STACKS_ITEMS'
export const FETCH_STACKS_FAILURE = 'FETCH_STACKS_FAILURE'

/**
 * portfolio.js
 */
export const START_FETCH_PORTFOLIOS = 'START_FETCH_PORTFOLIOS'
export const SET_PORTFOLIOS_ITEMS = 'SET_PORTFOLIOS_ITEMS'
export const FETCH_PORTFOLIOS_FAILURE = 'FETCH_PORTFOLIOS_FAILURE'
export const SET_PORTFOLIO_ITEM = 'SET_PORTFOLIO_ITEM'
export const FETCH_PORTFOLIO_FAILURE = 'FETCH_PORTFOLIO_FAILURE'

/**
 * vacancy.js
 */
export const START_FETCH_VACANCYS = 'START_FETCH_VACANCYS'
export const SET_VACANCYS_ITEMS = 'SET_VACANCYS_ITEMS'
export const FETCH_VACANCYS_FAILURE = 'FETCH_VACANCYS_FAILURE'
