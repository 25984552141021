import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import i18n from './i18n'
import './plugins'
import MasonryWall from '@yeger/vue2-masonry-wall'

Vue.config.productionTip = false

Vue.use(MasonryWall, VueGtag, {
  config: { id: process.env.VUE_APP_MY_ENV_G_TAG },
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
