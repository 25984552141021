import axios from 'axios'
import { api } from '../../config'
import * as types from '../mutation-types'

/**
 * Initial state
 */
export const state = {
  isLoading: false,
  vacancys: [],
}

/**
 * Actions
 */
export const actions = {
  async fetchVacancys ({ commit }) {
    commit(types.START_FETCH_VACANCYS)
    try {
      const { data } = await axios.get(api.path('vacancy'))
      const vacancys = data.data
      console.log('vacancys data: ' + JSON.stringify(vacancys))
      if (process.env.NODE_ENV === 'production') commit(types.SET_VACANCYS_ITEMS, vacancys)
      else {
        setTimeout(function () {
          commit(types.SET_VACANCYS_ITEMS, vacancys)
        }, 2500)
      }
    } catch (e) {
      console.log(e)
      commit(types.FETCH_VACANCYS_FAILURE)
    }
  },
}

/**
 * Mutations
 */
export const mutations = {
  [types.START_FETCH_VACANCYS] (state) {
    state.isLoading = true
  },
  [types.SET_VACANCYS_ITEMS] (state, vacancys) {
    state.isLoading = false
    state.vacancys = vacancys
  },
  [types.FETCH_VACANCYS_FAILURE] (state) {
    state.isLoading = false
    state.vacancys = []
  },
}

/**
 * Getters
 */
export const getters = {
  isLoading: state => state.isLoading,
  vacancys: state => state.vacancys,
}
