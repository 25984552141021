import axios from 'axios'
import { api } from '../../config'
import * as types from '../mutation-types'

/**
 * Initial state
 */
export const state = {
  isLoading: false,
  stacks: [],
}

/**
 * Actions
 */
export const actions = {
  async fetchStacks ({ commit }) {
    try {
      const { data } = await axios.get(api.path('stacks'))
      console.log('fetchStacks action data: ' + JSON.stringify(data))
      const stacks = data.data
      console.log('stacks data: ' + JSON.stringify(stacks))
      commit(types.SET_STACKS_ITEMS, stacks)
    } catch (e) {
      console.log(e)
      commit(types.FETCH_STACKS_FAILURE)
    }
  },
}

/**
 * Mutations
 */
export const mutations = {
  [types.SET_STACKS_ITEMS] (state, stacks) {
    state.isLoading = false
    state.stacks = stacks
  },
  [types.FETCH_STACKS_FAILURE] (state) {
    state.isLoading = false
    state.stacks = []
  },
}

/**
 * Getters
 */
export const getters = {
  isLoading: state => state.isLoading,
  stacks: stacks => state.stacks,
}
